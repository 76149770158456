<template>
  <!-- =======================
    Popular articles START -->
  <div class="container">
    <!-- Titles -->
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="text-center mb-4">Hot Products</h2>
      </div>
    </div>

    <div class="row g-4">
      <div class="col-xl-6" v-for="(prodcut, index) in hotProducts">
        <!-- Card item START -->
        <router-link :to="'/products-details?id='+ prodcut.id">
          <div class="card card-body border p-4 h-100">
            <!-- Title -->
            <h4 class="card-title mb-4">
              {{ prodcut.productName }}
            </h4>

            <!-- Avatar group and content -->
            <div class="d-sm-flex align-items-center">
              <!-- Avatar group -->
              <ul class="avatar-group mb-2 mb-sm-0" v-if="prodcut.photoUrl">
                <li class="avatar avatar-md">
                  <img class="avatar-img rounded-circle border-white" :src="prodcut.photoUrl" alt="avatar">
                </li>
              </ul>
              <!-- Content -->
              <div class="ms-sm-2">
                <h6 class="mb-1">{{ prodcut.englishName }}</h6>
                <p class="mb-0">{{ prodcut.cas }}</p>
              </div>
            </div>
          </div>
        </router-link>
        <!-- Card item END -->
      </div>
    </div> <!-- Row END -->
  </div>
  <!-- =======================
  Popular articles END -->

</template>

<script>
import { listHotProducts } from '@/request/api/product'

export default {
  name: 'ProductLatest',
  data () {
    return {
      hotProducts: []
    }
  },
  mounted () {
    // 正式数据
    this.allListHotProducts()
  },
  methods: {
    async allListHotProducts () {
      listHotProducts().then((res) => {
        this.hotProducts = res
      })
    }
  }
}
</script>

<style scoped>

</style>
