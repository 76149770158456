<template>
  <!-- =======================
  Blog START-->
  <div class="container">
    <!-- Title -->
    <div class="row mb-3 mb-sm-4">
      <div class="col-12 mx-auto text-center">
        <h2 class="h1">
          <span class="position-relative z-index-9">Latest </span>
          <span class="position-relative z-index-1">News & Events
					</span>
        </h2>
      </div>
    </div>

    <div class="row g-4 justify-content-between">
      <div class="col-md-5">
        <!-- Card START -->
        <div v-for="(news, index) in leftHotNews" class="card bg-transparent">
          <!-- Image -->
          <img :src="news.firstPhoneUrl" class="card-img" alt="">
          <!-- Card body -->
          <div class="card-body px-3">
            <h4>
              <router-link :to="'/news-detail?id='+news.id" class="stretched-link">{{ news.title }}</router-link>
            </h4>
            <p v-html="news.content"></p>
            <div class="d-flex justify-content-between">
              <h6>{{ news.createUser }}</h6>
              <span>{{ news.createTime }}</span>
            </div>
          </div>
        </div>
        <!-- Card END -->
      </div>

      <div class="col-md-7">
        <!-- Card START -->
        <template v-for="(news,index) in rightHotNews">
          <hr v-if="index != 0" class="my-4">

          <div class="card bg-transparent">
            <div class="row align-items-center">
              <div class="col-sm-4 col-lg-3">
                <div class="bg-dark text-center p-3 rounded-2 mb-2 mb-sm-0">
                  <h2 class="text-white">{{ news.month }}</h2>
                  <span class="text-white">{{ news.year }}</span>
                </div>
              </div>
              <div class="col-sm-8 col-lg-9">
                <div class="card-body p-0">
                  <h5 class="card-title"><a href="#" class="stretched-link">{{ news.title }}</a></h5>
                  <p class="mb-0">{{ news.createUser }}</p>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Card END -->
      </div>
    </div> <!-- Row END -->
  </div>
  <!-- =======================
  Blog START-->

</template>

<script>
import { listHot4News } from '@/request/api/news'

export default {
  name: 'HomeNews',
  data () {
    return {
      allHotNews: [],
      leftHotNews: [],
      rightHotNews: []
    }
  },
  mounted () {
    this.listHotNews()
  },
  methods: {
    listHotNews () {
      listHot4News().then(res => {
        this.allHotNews = res
        for (let i = 0; i < this.allHotNews.length; i++) {
          let news = this.allHotNews[i]
          news.content = news.content.replace(/<\/?(img)[^>]*>/gi, '')
          if (i == 0) {
            this.leftHotNews.push(news)
          }
          if (i > 0) {
            this.rightHotNews.push(news)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
