<template>
  <!-- =======================
  Featured course START -->
  <div class="container">
    <!-- Title -->
    <div class="row mb-4">
      <div class="col-lg-8 text-center mx-auto">
        <h2 class="fs-1 mb-0">FEATURED PRODUCTS</h2>
        <!--          <p class="mb-0">Explore top picks of the week </p>-->
      </div>
    </div>

    <div class="row g-4">
      <!-- Card Item START -->
      <div class="col-md-6 col-lg-4 col-xxl-3">
        <router-link to="/products">
          <div class="card p-2 shadow">
            <div class="rounded-top overflow-hidden">
              <div class="card-overlay-hover">
                <!-- Image -->
                <img src="assets/images/home/search.png" class="card-img-top" alt="course image">
              </div>
              <div class="card-body">
                <!-- Badge and icon -->
                <div class="d-flex justify-content-between">
                  <!-- Rating and info -->
                  <ul class="list-inline mb-0">
                    <!-- Info -->
                    <li class="list-inline-item d-flex justify-content-center align-items-center">
                      <h6 class="card-title"><a href="#">All Product</a></h6>
                    </li>
                  </ul>
                  <!-- Avatar -->
                  <div class="badge bg-dark">
                    MORE
                  </div>
                </div>
              </div>
            </div>
            <!-- Card body -->

          </div>
        </router-link>
      </div>
      <!-- Card Item END -->

      <div class="col-md-6 col-lg-4 col-xxl-3">
        <router-link to="/products">
          <div class="card p-2 shadow">
            <div class="rounded-top overflow-hidden">
              <div class="card-overlay-hover">
                <!-- Image -->
                <img src="assets/images/home/serach_2.png" class="card-img-top" alt="course image">
              </div>
              <div class="card-body">
                <!-- Badge and icon -->
                <div class="d-flex justify-content-between">
                  <!-- Rating and info -->
                  <ul class="list-inline mb-0">
                    <!-- Info -->
                    <li class="list-inline-item d-flex justify-content-center align-items-center">
                      <h6 class="card-title"><a href="#">New Energy Materials</a></h6>
                    </li>
                  </ul>
                  <!-- Avatar -->
                  <div class="badge bg-dark">
                    MORE
                  </div>
                </div>
              </div>
            </div>
            <!-- Card body -->

          </div>
        </router-link>
      </div>

      <div class="col-md-6 col-lg-4 col-xxl-3">
        <router-link to="/products">
          <div class="card p-2 shadow">
            <div class="rounded-top overflow-hidden">
              <div class="card-overlay-hover">
                <!-- Image -->
                <img src="assets/images/home/search_3.png" class="card-img-top" alt="course image">
              </div>
              <div class="card-body">
                <!-- Badge and icon -->
                <div class="d-flex justify-content-between">
                  <!-- Rating and info -->
                  <ul class="list-inline mb-0">
                    <!-- Info -->
                    <li class="list-inline-item d-flex justify-content-center align-items-center">
                      <h6 class="card-title"><a href="#">Fine Chemicals</a></h6>
                    </li>
                  </ul>
                  <!-- Avatar -->
                  <div class="badge bg-dark">
                    MORE
                  </div>
                </div>
              </div>
            </div>
            <!-- Card body -->
          </div>
        </router-link>
      </div>

      <div class="col-md-6 col-lg-4 col-xxl-3">
        <router-link to="/products">
          <div class="card p-2 shadow">
            <div class="rounded-top overflow-hidden">
              <div class="card-overlay-hover">
                <!-- Image -->
                <img src="assets/images/home/search_4.png" class="card-img-top" alt="course image">
              </div>
              <div class="card-body">
                <!-- Badge and icon -->
                <div class="d-flex justify-content-between">
                  <!-- Rating and info -->
                  <ul class="list-inline mb-0">
                    <!-- Info -->
                    <li class="list-inline-item d-flex justify-content-center align-items-center">
                      <h6 class="card-title"><a href="#">Interedites</a></h6>
                    </li>
                  </ul>
                  <!-- Avatar -->
                  <div class="badge bg-dark">
                    MORE
                  </div>
                </div>
              </div>
            </div>
            <!-- Card body -->

          </div>
        </router-link>
      </div>
    </div>
    <!-- Button -->
    <!--      <div class="text-center mt-5">-->
    <!--        <a href="#" class="btn btn-primary-soft">View more<i class="fas fa-sync ms-2"></i></a>-->
    <!--      </div>-->
  </div>
  <!-- =======================
  Featured course END -->
</template>

<script>
export default {
  name: 'HomeFeature'
}
</script>

<style scoped>

</style>
