<template>
  <!-- =======================
  Footer START -->
  <footer class="pt-5 bg-light pb-5">
    <div class="container">
      <!-- Row START -->
      <div class="row g-4">
        <!-- Widget 1 START -->
        <div class="col-lg-3">
          <!-- logo -->
          <a class="me-0" href="index-1.html">
            <img class="light-mode-item h-40px" src="/logo.png" alt="logo">
            <img class="dark-mode-item h-40px" src="/logo.png" alt="logo">
          </a>
          <p class="my-3">New Fortune Industrial Co.,Limited is specialize in the fields of New Energy Materials,Fine chemicals & Intermediates in Pharmaceuticals for two decades since 2003.</p>
          <!-- Social media icon -->
<!--          <ul class="list-inline mb-0 mt-3">-->
<!--            <li class="list-inline-item"><a class="btn btn-white btn-sm shadow px-2 text-facebook" href="#"><i-->
<!--              class="fab fa-fw fa-facebook-f"></i></a></li>-->
<!--            <li class="list-inline-item"><a class="btn btn-white btn-sm shadow px-2 text-instagram" href="#"><i-->
<!--              class="fab fa-fw fa-instagram"></i></a></li>-->
<!--            <li class="list-inline-item"><a class="btn btn-white btn-sm shadow px-2 text-twitter" href="#"><i-->
<!--              class="fab fa-fw fa-twitter"></i></a></li>-->
<!--            <li class="list-inline-item"><a class="btn btn-white btn-sm shadow px-2 text-linkedin" href="#"><i-->
<!--              class="fab fa-fw fa-linkedin-in"></i></a></li>-->
<!--          </ul>-->
        </div>
        <!-- Widget 1 END -->

        <!-- Widget 2 START -->
        <div class="col-lg-3">
          <div class="row g-4">
            <!-- Link block -->
            <div class="col-12 col-md-6">
              <h5 class="mb-2 mb-md-4">Company</h5>
              <ul class="nav flex-column">
                <li class="nav-item"><router-link class="nav-link" to="/about">About us</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/products">Products</router-link></li>
<!--                <li class="nav-item"><router-link class="nav-link" to="/services">Services</router-link></li>-->
                <li class="nav-item"><router-link class="nav-link" to="/news">News & Events</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/contact">Contact us</router-link></li>

<!--                <li class="nav-item"><a class="nav-link" href="#">Library</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" href="#">Career</a></li>-->
              </ul>
            </div>

            <!-- Link block -->
<!--            <div class="col-6 col-md-4">-->
<!--              <h5 class="mb-2 mb-md-4">Community</h5>-->
<!--              <ul class="nav flex-column">-->
<!--                <li class="nav-item"><a class="nav-link" href="#">XXX</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" href="#">XXX</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" href="#">XXX</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" href="#">XXX</a></li>-->
<!--              </ul>-->
<!--            </div>-->

            <!-- Link block -->
<!--            <div class="col-6 col-md-4">-->
<!--              <h5 class="mb-2 mb-md-4">Teaching</h5>-->
<!--              <ul class="nav flex-column">-->
<!--                <li class="nav-item"><a class="nav-link" href="#">Who are we</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" href="#">What are we doing</a></li>-->
<!--                <li class="nav-item"><a class="nav-link" href="#">Where shall we go</a></li>-->
<!--              </ul>-->
<!--            </div>-->
          </div>
        </div>
        <!-- Widget 2 END -->

        <!-- Widget 3 START -->
        <div class="col-lg-6">
          <h5 class="mb-2 mb-md-4">Contact</h5>
          <!-- Time -->
          <p class="mb-2">
            Email :<span class="h6 fw-light ms-2">sinokong@vip.163.com & contakt@163.com & 2230604266@qq.com</span>
            <span class="d-block small"></span>
          </p>

          <p class="mb-0">Phone:<span class="h6 fw-light ms-2">+86-18951747012 & +86-25-86459456</span></p>
          <p class="mb-0">Address:<span class="h6 fw-light ms-2">Room 701 Bldg.5,Jiqingli,No.40 Fentai South Road,Yuhuatai District,Nanjing China</span></p>

          <!--          <div class="row g-2 mt-2">-->
<!--            &lt;!&ndash; Google play store button &ndash;&gt;-->
<!--            <div class="col-6 col-sm-4 col-md-3 col-lg-6">-->
<!--              <a href="#"> <img src="assets/images/client/google-play.svg" alt=""> </a>-->
<!--            </div>-->
<!--            &lt;!&ndash; App store button &ndash;&gt;-->
<!--            <div class="col-6 col-sm-4 col-md-3 col-lg-6">-->
<!--              <a href="#"> <img src="assets/images/client/app-store.svg" alt="app-store"> </a>-->
<!--            </div>-->
<!--          </div> &lt;!&ndash; Row END &ndash;&gt;-->
        </div>
        <!-- Widget 3 END -->
      </div><!-- Row END -->

      <!-- Divider -->
<!--      <hr class="mt-4 mb-0">-->

      <!-- Bottom footer -->
<!--      <div class="py-3">-->
<!--        <div class="container px-0">-->
<!--          <div class="d-md-flex justify-content-between align-items-center py-3 text-center text-md-left">-->
<!--            &lt;!&ndash; copyright text &ndash;&gt;-->
<!--            <div class="text-primary-hover"> Copyrights <a href="#" class="text-body">©2021 Eduport</a>. All rights-->
<!--              reserved.-->
<!--            </div>-->
<!--            &lt;!&ndash; copyright links&ndash;&gt;-->
<!--            <div class=" mt-3 mt-md-0">-->
<!--              <ul class="list-inline mb-0">-->
<!--                <li class="list-inline-item">-->
<!--                  &lt;!&ndash; Language selector &ndash;&gt;-->
<!--                  <div class="dropup mt-0 text-center text-sm-end">-->
<!--                    <a class="dropdown-toggle nav-link" href="#" role="button" id="languageSwitcher"-->
<!--                       data-bs-toggle="dropdown" aria-expanded="false">-->
<!--                      <i class="fas fa-globe me-2"></i>Language-->
<!--                    </a>-->
<!--                    <ul class="dropdown-menu min-w-auto" aria-labelledby="languageSwitcher">-->
<!--                      <li><a class="dropdown-item me-4" href="#"><img class="fa-fw me-2"-->
<!--                                                                      src="assets/images/flags/uk.svg"-->
<!--                                                                      alt="">English</a></li>-->
<!--                      <li><a class="dropdown-item me-4" href="#"><img class="fa-fw me-2"-->
<!--                                                                      src="assets/images/flags/gr.svg" alt="">German-->
<!--                      </a></li>-->
<!--                      <li><a class="dropdown-item me-4" href="#"><img class="fa-fw me-2"-->
<!--                                                                      src="assets/images/flags/sp.svg" alt="">French</a>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </li>-->
<!--                <li class="list-inline-item"><a class="nav-link" href="#">Terms of use</a></li>-->
<!--                <li class="list-inline-item"><a class="nav-link pe-0" href="#">Privacy policy</a></li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </footer>
  <!-- =======================
  Footer END -->
</template>

<script lang="ts">


</script>

<style scoped>

</style>
