<template>
  <!-- =======================
  About START -->
  <div class="container">
    <div class="row mb-4">
      <div class="col-lg-8 text-center mx-auto">
        <h2 class="fs-1 mb-0">OUR QUALIFICATIONS</h2>
        <!--          <p class="mb-0">Explore top picks of the week </p>-->
      </div>
    </div>
    <div class="row g-4">

      <!-- Action box item -->
      <div class="col-lg-6 test-class">
        <div class="bg-blur bg-opacity-50 h-100 p-5 text-center rounded-3 position-relative">
          <!-- Svg decoration -->
          <img src="assets/images/about/qualification_1.jpeg" class="opacity-5 mb-n5" alt="">
          <!-- Title and content -->
          <h5 class="mb-3 lead mt-6">ISO - 9001</h5>
          <h4 class="mb-4">quality management system certificate</h4>

        </div>
      </div>

      <!-- Action box item -->
      <div class="col-lg-6 test-class">
        <div class="bg-blur h-100 p-5 text-center rounded-3 position-relative">
          <img src="assets/images/about/qualification_2.jpeg" class="opacity-5 mb-n5" alt="">
          <!-- Title and content -->
          <h5 class="mb-3 lead mt-6">CERTIFICATE</h5>
          <h4 class="mb-4">certificate of incorporation of a private limited company.</h4>
        </div>
      </div>
    </div> <!-- Row END -->
  </div>
</template>

<script>
export default {
  name: 'AboutWeQualifications',
  mounted () {
    // 禁用复制
    //  this.$nextTick(() => {
    //    document.onselectstart = new Function('event.returnValue=false')
    //  })
  },
}
</script>

<style scoped>
.test-class {
  user-select: none;
}
</style>
