import httpRequest from '@/request'

/**
 * 得到热门数据信息
 */
let listHotProducts = () => {
  return httpRequest({
    url: '/apiV2/product/listHotProducts',
    method: 'post',
    data: {}
  })
}

/**
 * 得到热门数据信息
 */
let listProducts = (page: bigint, limit: bigint, search: string, categoryid: string) => {
  return httpRequest({
    url: '/apiV2/product/listProducts',
    method: 'post',
    data: {
      page: page,
      limit: limit,
      search: search,
      categoryid: categoryid
    }
  })
}

/**
 * 得到热门数据信息
 */
let productKeyPress = (page: bigint, limit: bigint, search: string) => {
  return httpRequest({
    url: '/apiV2/product/keyPress',
    method: 'post',
    data: {
      page: page,
      limit: limit,
      search: search
    }
  })
}

/**
 * 商品详情
 */
let productDetail = (id: string) => {
  return httpRequest({
    url: '/apiV2/product/getProduct/' + id,
    method: 'get'
  })
}

export {
  listHotProducts,
  productDetail,
  listProducts,
  productKeyPress
}
