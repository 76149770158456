<template>
  <!-- Back to top -->
<!--  <div class="back-top">-->
<!--    <i class="bi bi-arrow-up-short position-absolute top-50 start-50 translate-middle"></i>-->
<!--  </div>-->
</template>

<script>
export default {
  name: 'LayoutBackTop'
}
</script>

<style scoped>

</style>
