<template>
  <!-- =======================
  Blog START-->
  <div class="container">
    <!-- Title -->
    <div class="row mb-3 mb-sm-4">
      <div class="col-12 mx-auto text-center">
        <h2 class="h1">
          <span class="position-relative z-index-9">Enterprise development history </span>
        </h2>
      </div>
    </div>

    <div class="row g-4 justify-content-between">
      <div class="col-md-12">
        <!-- Card START -->
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2022</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">certified by ISO 9001:2005 </p>
                <p class="mb-0">company web site’ birth year</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2019</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">visited by A Professional Multinational Consumer Goods Corp. Shanghai office</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2015</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">audited by A Global Biopharma. Group in France</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2014</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">audited by A Global Enterprise with Core Competencies
                  in Specialties in Germany and approved by the Inspectors</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2012</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">audited by A Global Pharma. in Belgium</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2008</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">succeeded in REACH Pre-Registration</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2007</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">first audited by A Latvia-based pharma customer in Riga</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2005</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">the company--New Fortune Industrial Co.,Limited set up</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2004</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">visited by A Key manager of the Professional Swiss-based company in Sevelen to our main
                  production partners and labs</p>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-1">
        <div class="card bg-transparent">
          <div class="row align-items-center">
            <div class="col-sm-2 col-lg-1">
              <h3 class="text-white bg-dark">2003</h3>
            </div>
            <div class="col-sm-10 col-lg-10">
              <div class="card-body p-0">
                <p class="mb-0">acting as the Swiss China office,the export started mainly via the most closed business
                  partners in Switzerland.</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Card END -->
      </div>
    </div> <!-- Row END -->
  </div>
  <!-- =======================
  Blog START-->

</template>

<script>
</script>

<style scoped>

</style>
