import httpRequest from '@/request'

/**
 * 得到热门News信息
 */
let listHot4News = () => {
  return httpRequest({
    url: '/apiV2/news/listHostNews/4',
    method: 'post',
    data: {}
  })
}

let listNews = (current: number, limit: number, search: string, categoryid: string) => {
  return httpRequest({
    url: '/apiV2/news/listNews',
    method: 'post',
    data: {
      page: current,
      limit: limit,
      search: search,
      categoryid: categoryid
    }
  })
}

let getNewsDetail = (id: string) =>{
  return httpRequest({
    url: '/apiV2/news/getModel/'+ id,
    method: 'post',
    data: {
    }
  })
}

export {
  listHot4News,
  listNews,
  getNewsDetail
}
