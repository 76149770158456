<template>
  <div class="bg-white">
    <!--  <nav>-->
    <!--    <router-link to="/">Home</router-link> |-->
    <!--    <router-link to="/about">About</router-link>-->
    <!--  </nav>-->
    <layout-header></layout-header>
    <router-view/>

    <layout-footer></layout-footer>
    <!--    <layout-back-top></layout-back-top>-->
  </div>
</template>

<style lang="scss">
////全局选择
//* {
//  user-select: none;
//}
//
////或者部分标签
//.test-class {
//  user-select: none;
//}
</style>
<script>
import LayoutHeader from '@/layout/LayoutHeader'
import LayoutFooter from '@/layout/LayoutFooter.vue'
import LayoutBackTop from '@/layout/LayoutBackTop.vue'

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    LayoutBackTop
  },
  mounted () {
    // 禁用复制
    // this.$nextTick(() => {
    //   document.onselectstart = new Function('event.returnValue=false')
    // })
  },
}
</script>
