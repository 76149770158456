<template>
  <div class="container">
    <!-- Category START -->
    <div class="row g-4">
      <!-- Category item -->
      <div class="col-sm-6 col-lg-12 col-xl-3">
        <h2>Services</h2>
        <p>New Fortune Industrial Co.,Limited can do an entire work from a beginning enquiry to the end of shipment</p>
      </div>

      <!-- Category item -->
      <div class="col-sm-6 col-lg-4 col-xl-3">
        <div class="card card-body shadow h-100">
          <ul class="list-group list-group-borderless small mt-2">
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To evaluate an enquiry in theory whether to enter into next step firstly
            </li>
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To make a synthetic route to decide its possibility
            </li>
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To look for the raw materials to be ready for the reaction
            </li>
          </ul>
        </div>
      </div>

      <!-- Category item -->
      <div class="col-sm-6 col-lg-4 col-xl-3">
        <div class="card card-body shadow h-100">
          <ul class="list-group list-group-borderless small mt-2">
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To arrange a lab sample so as to test the reality comparing with the standard sample
            </li>
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To test via NMR/HPLC/GC to prove its Character and Purity/Content/Assay
            </li>
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To send the sample for the customers’ approval
            </li>
          </ul>
        </div>
      </div>

      <!-- Category item -->
      <div class="col-sm-6 col-lg-4 col-xl-3">
        <div class="card card-body shadow h-100">
          <ul class="list-group list-group-borderless small mt-2">
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To proceed a lab/pilot/bulk production based on the POs
            </li>
            <li class="list-group-item text-body pb-0">
              <span class="text-info"> <i class="fa fa-circle"></i> </span>
              To book a shipping space for the shipment by Sea/Air/Combined
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Category END -->
  </div>
</template>

<script>
export default {
  name: 'HomeServices'
}
</script>

<style scoped>
</style>
