import { createStore } from 'vuex'

export default createStore({
  state: {
    currentMenu: ''
  },
  getters: {
    getCurrentMenu(state) {
      return state.currentMenu;
    },
  },
  mutations: {
    setCurrentMenu(state, currentMenu: string) {
      state.currentMenu = currentMenu;
    },
  },
  actions: {
  },
  modules: {
  }
})
